<template>
    <div class="card">
        <div class="card-header bg-black">
            <h3 class="card-title">
                Dirección adicional
            </h3>
                <div class="card-tools">
                    <button type="button" @click="guardar()" class="btn btn-block btn-success btn-xs" >{{ $t('general.guardar') }}</button>            
            </div>
        </div><!-- /.card-header -->
        <div class="card-body">

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">{{ $t('general.direccion') }}</label>
                        <input type="text" v-model="direccion.direccion" class="form-control input-sm">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label for="exampleInputEmail1">{{ $t('general.poblacion') }}</label>
                        <input type="text" v-model="direccion.poblacion" class="form-control input-sm">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="exampleInputEmail1">{{ $t('general.cpostal') }}</label>
                        <input type="text" v-model="direccion.cp" class="form-control input-sm">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">{{ $t('general.provincia') }}</label>
                        <input type="text" v-model="direccion.provincia" class="form-control input-sm">
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio', 'asegurado','direccionaeditar','editar','ampliarmapa'], //this.$props.asegurado.idcliente
    data() {
        return {
            direccion:[],
        };
    },
    methods: {
        async guardar() {
            //PUT  modulos/pwgsapi/index.php/clientes/IDCLIENTE/modificar-cliente-servicio/IDSERVICIO //this.$props.asegurado.idcliente
            const api = new PwgsApi();
            if (this.$props.editar == false) { this.direccion.id = "0" }
            let subidadatos = {
                                adicionales_direcciones_ids: [this.direccion.id],
                                adicionales_direcciones: [this.direccion.direccion],
                                adicionales_poblaciones: [this.direccion.poblacion],
                                adicionales_cps: [this.direccion.cp],
                                adicionales_provincias: [this.direccion.provincia]
                              }
            try {
                await api.put('clientes/' + this.$props.asegurado.idcliente + '/modificar-cliente-servicio/' + this.$props.id, subidadatos);
                this.$parent.cargarcliente();
                this.$parent.ventanaaux = false;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Direccion guardada correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
         }
    },
    mounted() {
        if (this.$props.editar == true) this.direccion = this.$props.direccionaeditar;
        else this.direccion = [];
    },
    watch: {
        id() {
            if (this.$props.editar == true) this.direccion = this.$props.direccionaeditar;
            else this.direccion = [];
        },
        editar() { 
            if (this.$props.editar == true) this.direccion = this.$props.direccionaeditar;
            else this.direccion = [];
        },
        direccionaeditar() { 
            if (this.$props.editar == true) this.direccion = this.$props.direccionaeditar;
            else this.direccion = [];
        }
    }
})
</script>
