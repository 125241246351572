<template>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-map-pin mr-1"></i> {{ $t('general.direccion') }}
            <p class="">
                <strong>{{ asegurado.direccion }}</strong>
            </p>
        </div>
        
    </div>
    <div class="row">
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.ciudad') }}
            <p class="">
                <strong>{{ asegurado.poblacion }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marker-alt mr-1"></i>{{ $t('general.cpostal') }}
            <p class="">
                <strong>{{ asegurado.codigo_postal }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-map-marked-alt mr-1"></i> {{ $t('general.provincia') }}
            <p class="">
                <strong>{{ asegurado.provincia }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header bg-black">
                    <h4 class="card-title">
                        <i class="far fa-address-card mr-2"></i>
                        Direcciones adicionales
                    </h4>
                    <div class="card-tools">
                        <button type="button" @click="addicional()" class="btn btn-block btn-primary btn-xs" dat>{{ $t('general.anadir') }}</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>{{ $t('general.poblacion') }}</th>
                                <th>{{ $t('general.direccion') }}</th>
                                <th style="width: 40px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="direccion in direcciones" :key="direccion.id">
                                <td>{{direccion.poblacion}}</td>
                                <td>{{direccion.direccion}}</td>
                                <td><button @click="modificar(direccion)" class="btn btn-warning btn-xs">{{ $t('general.editar') }}</button></td>
                            </tr>
                        </tbody>
                    </table>                     
                </div>
            </div>
        </div>
    </div>
    <servicio-datos-asegurado-direccion  v-if="ventanaaux" :editar="editar" :direccionaeditar="direccionaeditar" :id="id" :cliente="cliente" :asegurado="asegurado" :datoscliente="datoscliente" :servicio="servicio"/>
</template>

<script>  

import { PwgsApi } from '../../../../../services/PwgsApi';
import serviciodatosaseguradodireccion from './direccion_form_adicional.vue';

export default ({
        props: ['id', 'servicio', 'datoscliente','asegurado','ampliarmapa'],

    setup() {     
    },
    components: {
        'servicio-datos-asegurado-direccion': serviciodatosaseguradodireccion,
    },
    data() {
        return {
            ventanaaux: false,
            direcciones: [],
            editar: false,
            direccionaeditar: [],

        }
    },
    methods: {
        addicional() { 

            this.ventanaaux = true;
            this.editar = false;

        },
        async cargarcliente() { 
            const api = new PwgsApi;
            const datos = await api.get("clientes/" + this.$props.asegurado.idcliente);
            this.direcciones = datos.datos[0].direcciones;

        },
        modificar(direccion) { 
            this.editar = true;
            this.ventanaaux = true;
            this.direccionaeditar = direccion;
        }

    },
    mounted() { 
        this.ventanaaux = false;
        this.cargarcliente();

    },
    watch: {
        id(){
            this.ventanaaux = false;
            this.cargarcliente();
        }
    }
})


</script>